
body, html {
  @include background-size(url('../images/bg.jpg'));
  font-family: 'dinregular';
  min-height: 100%;
  color: $white;
  position: absolute;
  width: 100%; 
  // position: relative;
  @media screen and (max-width: 900px){
    overflow-x: hidden;
  }
}
body{
  margin: 0;
}

  /*Heder*/

  header.header_main{
    position: relative;
    float: right;
    padding: 15px 40px 0;
    width: 100%;
    .bt_movil{
      cursor: pointer;
      display: none;
      float: right;
      margin-left: 20px;
      i{
        font-size: 25px;
        line-height: 28px;
      }
      @media screen and(max-width: 900px){
        display: block;
      }
    }
    .activemenu::after{
      width: calc(100vh - 130px);
      height: 100%;
      content: '';
      display: block;
      position: fixed;
      top: 0;
      left: 0;
    }

    .bts_language{
      float: right;
      a{
        @include animado(0.3s);
        font-family: 'dinregular';
        border: solid 1px $white;
        color: $white;
        font-size: 10px;
        float: left;
        height: 30px;
        line-height: 28px;
        text-align: center;
        width: 30px;
        &+a{
          margin-left: 5px;
        }
        &.active,
        &:hover{
          background: $white;
          color: black;
        }
      }
    }
    .ico_shopping{
      background: url('../images/icons/icon_shoping.png') no-repeat center center; 
      float: right;
      height: 30px;
      margin-right: 30px;
      position: relative;
      width: 30px;
      .cont{
        font-family: 'dinblack';
        background: $white;
        border: solid $black 1px;
        bottom: -5px;
        color: $black;
        font-size: 10px;
        @include border-radius(8px);
        height: 18px;
        line-height: 16px;
        position: absolute;
        right: -5px;
        text-align: center;
        width: 18px;
      }
    }
    @media screen and (max-width: 900px){
      @include background-size(url('../images/bg.jpg'));
      box-shadow: 0px 3px 20px 0px rgba(255,252,255,0.3);
      padding-bottom: 15px;
      position: fixed;
      z-index: $max-index + 1;
    }
  }

  .logo_main{
    left: 40px;
    position: absolute;
    top: 40px;
    img{
      width: 100%;
    }
    @media screen and (max-width: 900px){
      position: static;
      width: 150px;
      display: block;
      margin: 90px auto 50px;
    }
  }

  .nav_main{
    position: fixed;
    transform: translateY(-50%);
    right: 60px;
    top: 50%;
    width: 130px;
    z-index: $max-index + 20;
    ul{
      border-left: solid 1px $white;
      opacity: 0.7;
      padding: 15px 0;
      li{
        a{
          font-family: 'dinbold';
          color: $white;
          font-size: 11px;
          line-height: 13px;
          letter-spacing: 1px;
          padding: 0 15px 0 30px;
          position: relative;
          text-transform: uppercase;
          &.active,
          &:hover{
            &:before{
              background: $white;
              content: "";
              height: 100%;
              left: 0;
              position: absolute;
              top: 0;
              width: 4px;
            }
          }
        }
        &+li{
          margin-top: 15px;
        }
      }
    }
    @media screen and (max-width: 1170px){
      right: 20px;
    }
    @media screen and (max-width: 900px){
      @include background-size(url('../images/bg.jpg'));
      border-left: solid 1px #ffffff;
      height: 100%;
      padding-top: 20px;
      position: fixed;
      transform: none;
      top: 0;
      right: -130px;
      ul{
        border: none;
      }
    }
  }

  /*Footer*/

  footer.footer_main{
    // float: left;
    // margin-top: 150px;
    text-align: left;
    padding: 0 40px 30px;
    position: absolute;
    // bottom: 0;
    width: 100%;
    height: 70px;
    .copy{
      color: $white;
      font-family: 'dinbold';
      font-size: 6px;
      display: inline-block;
      margin-right: 15px;
      vertical-align: bottom;
      @media screen and (max-width: 400px){
        display: block;
        margin-bottom: 20px;
      }
    }
    .bts_redes{
      display: inline-block;
      vertical-align: bottom;
      @media screen and (max-width: 480){
        margin-bottom: 20px;
      }
      a{
        @include animado(0.3s);
        border: solid 1px $white;
        color: $white;
        font-size: 10px;
        float: left;
        height: 30px;
        line-height: 28px;
        text-align: center;
        width: 30px;
        i{
          line-height: 28px;
        }
        &+a{
          margin-left: 5px;
        }
        &:hover{
          background: $white;
          color: black;
        }
      }
    }
    .ico_footer{
      position: absolute;
      transform: translateX(-50%);
      left: 50%;
      top: -20px;
      @media screen and (max-width: 900px){
        top: -90px;
      }
    }
    @media screen and (max-width: 900px){
      text-align: center;
    }
  }

  /*Content*/

h2.tit_section{
  color: $white;
  font-size: 16px;
  display: block;
  font-family: 'dinblack';
  line-height: 18px;
  text-align: center;
  margin-bottom: 30px;
}

.wrrp_main{
  @include clearfix();
  height: 100vh;
  @media screen and (max-width: 900px){
    position: absolute;
    width: 100%;
    left: 0;
    top: 0;
  }
}

.container{
  color: $white;
  @include clearfix();
  margin: 0 auto;
  width: 750px;
  margin-bottom: 150px;
  @media screen and (max-width:1170px) {
    margin-right: 200px;
  }
  @media screen and (max-width:1000px) {
    width: 550px;
  }
  @media screen and (max-width: 900px){
    margin-right: auto;
  }
  @media screen and (max-width: 550px){
    max-width: 450px;
    padding: 0 20px;
    width: 100%;
  }
}

.container_forms{
  @extend .container;
  width: 100%;
  max-width: 350px;
  @media screen and (max-width: 1170px){
    margin-right: auto;
  }
}

.header_contents{
  @include clearfix();
  float: left;
  text-align: center;
  width: 100%;
  & + .tit_section{
    margin-top: 30px;
    float: left;
    text-align: center;
    width: 100%;
  }
  img{
    float: none;
    max-width: 80%;
    &.toptit{
      margin-bottom: 15px;
    }
    &.bottomtit{
      margin: 15px 0 30px;
    }
  }
  h3.subtit_main{
    color: $white;
    margin-top: 25px;
    margin-bottom: 100px;
    font-family: 'dinmedium';
    font-size: 10px;
    line-height: 12px;
    @media screen and (max-width: 900px){
      margin-bottom: 40px;
    }
  }
}
.catalogo{
  text-align: center;
  width: 100%;
  li{
    display: inline-block;
    margin-top: 40px;
    margin-bottom: 40px;
    margin-left: 20px;
    margin-right: 20px;
    text-align: left;
    vertical-align: top;
    width: 200px;
    .img_cat{
      background: url('../images/bg_items.png') no-repeat center center; 
      height: 240px;
      width: 200px;
      img{
        display: block;
        padding-top: 20px;
        margin-left: auto;
        margin-right: auto;
      }
    }
    .info_cat{
      padding: 20px 20px 0;
      text-align: center;
    }
    h3{
      color: $white;
      font-family: 'dinblack';
      text-transform: uppercase;
      font-size: 16px;
      line-height: 18px;
      margin-bottom: 15px;
    }
    p, label{
      color: $white;
      font-family: 'dinmedium';
      font-size: 11px;
      line-height: 13px;
    }
    p{
      margin-bottom: 10px;
      span{
        font-size: 15px;
        text-decoration:line-through;
      }
    }
    .campo_form{
      display: inline-block;
      margin-bottom: 10px;
      vertical-align: middle;
      input[type="text"]{
        background: #636363;
        font-family: 'dinmedium';
        display: inline-block;
        text-align: center;
        margin-left: 10px;
        vertical-align: middle;
        line-height: 22px;
        border: $white solid 1px;
        color: $white;
        text-align: 10px;
        width: 40px;
      }
    }
    p.price{
      font-family: 'dinlight';
      font-size: 32px;
      line-height: 34px;
    }
    &+li{
      // margin-left: 40px;
      @media screen and (max-width: 550px){
        margin-left: 0;
      }
    }
  }
  // li:nth-child(4){
  //   margin-left: 0;
  // }
}

.catalogo_withlogin{
  @extend .catalogo;
  li{
    margin-bottom: 40px;
    width: 350px;
    .img_cat{
      display: inline-block;
      vertical-align: middle;
      width: 200px;
    }
    .info_cat{
      display: inline-block;
      vertical-align: middle;
      padding: 0;
      width: 140px;
    }
    button.button{
      margin-top: 10px;
      padding: 10px 15px;
    }
    h3{
      font-size: 14px;
      line-height: 16px;
    }
    p{
      margin-bottom: 5px;
    }
    p.price{
      font-size: 30px;
      line-height: 32px;
    }
  }
}

button.button, a.button{
  @include animado(0.3s);
  background: none;
  border: $white solid 1px;
  color: $white;
  cursor: pointer;
  font-family: 'Pepperonibold';
  font-size: 16px;
  margin-top: 16px;
  padding: 10px 30px;
  text-transform: uppercase;
  &:hover{
    background: $white;
    color: black;
  }
  &.continuebutton{
    display: block;
    margin: 30px auto 0;
    background: $white;
    color: black;
    &:hover{
      background: transparent;
      color: $white;
    }
  }
}

.wrrp_shopingcar{
  background: rgba(255,255,255,0.1);
  color: $white;
  border: solid 1px #858585;
  float: left;
  width: 100%;
  .head_shopingcar{
    border-bottom: solid 1px #858585;
    @include clearfix();
    p{
      font-size: 14px;
      line-height: 50px;
      float: left;
      padding-left: 40px;
    }
    a{
      text-decoration: underline;
      font-size: 12px;
      line-height: 50px;
      float: right;
      padding-right: 40px;
      &:hover{
        text-decoration: none;
      }
    }
    @media screen and (max-width: 550px){
      padding: 20px 0;
      a, p{
        display: block;
        text-align: center;
        padding: 0;
        line-height: normal;
        width: 100%;
      }
      p{
        margin-bottom: 10px;
      }
    }
  }
  .head_shopingcar--footer{
    p:nth-child(2) {
      font-size: 16px;
      float: right;
      padding-right: 40px;
      @media screen and (max-width: 550px){
        padding-right: 0;
      }
    }
    border-bottom: none;
    border-top: solid 1px #858585;
    text-transform: uppercase;
  }
  .content_shpingcar{
    @include clearfix();
    float: left;
    padding: 10px 40px 30px;
    width: 100%;
    @media screen and (max-width: 1000px){
      padding: 10px 20px 30px 10px;
    }
  }
  .car_list{
    float: left;
    width: 100%;
    li{
      float: left;
      width: 100%;
      margin-bottom: 60px;
      .table_car{
        display: table;
        table-layout:fixed;
        width: 100%;
        @media screen and (max-width: 550px){
          display: block;
        }
        div{
          display: table-cell;
          vertical-align: middle;
          &.desc{
            text-align: left;
            width: 250px;
            img, h3{
              display: inline-block;
              vertical-align: middle;
            }
            img{
              // height: 200px;
              width: 100px;
            }
            h3{
              font-family: 'dinblack';
              font-size: 16px;
              line-height: 18px;
              margin-left: 8px;
              text-transform: uppercase;
              @include calc(width, "100% - 125px");
            }
            @media screen and (max-width: 550px){
              margin-bottom: 10px;
              width: 100%;
            }
          }
          &.quantity{
            text-align: center;
            .campo_form{
              display: inline-block;
              vertical-align: middle;
              label{
                display: inline-block;
                font-size: 12px;
                font-family: 'dinmedium';
                margin-bottom: 10px;
              }
              input[type="text"]{
                background: #636363;
                font-family: 'dinmedium';
                display: inline-block;
                text-align: center;
                vertical-align: middle;
                line-height: 22px;
                border: $white solid 1px;
                color: $white;
                text-align: 10px;
                width: 40px;
              }
            }
            @include calc(width, "100% - 395px");
            @media screen and (max-width: 550px){
              float: left;
              width: 50%;
            }
          }
          &.price{
            text-align: left;
            width: 145px;
            span{
              display: inline-block;
              font-size: 12px;
              font-family: 'dinmedium';
              margin-bottom: 10px;
            }
            span.price{
              display: inline-block;
              font-size: 30px;
              font-family: 'dinmedium';
              margin-bottom: 10px;
            }
            p.price{
              font-size: 30px;
              line-height: 20px;
            }
            @media screen and (max-width: 550px){
              float: left;
              text-align: center;
              width: 50%;
            }
          }
          @media screen and (max-width: 550px){
            display: block;
          }
        }
      }
      .foot_itshopcar{
        text-align: right;
        width: 100%;
        a, span{
          display: inline-block;
          vertical-align: middle;
        }
        a{
          font-family: 'dinmedium';
          font-size: 11px;
          line-height: 12px;
          text-transform: uppercase;
          text-decoration: underline;
          &:hover{
            text-decoration: none;
          }
        }
        span{
          background: $white;
          height: 20px;
          margin: 0 5px;
          width: 1px;
        }
        @media screen and (max-width: 550px){
          text-align: center;
          margin: 40px 0;
        }
      }
      @media screen and (max-width: 550px){
        max-width: 450px;
        padding: 0;
        width: 100%;
      }
    }
    li:last-child {
      margin-bottom: 0;
    }
  }
}

.wrrp_myshoping{
  @extend .wrrp_shopingcar;
  & + .wrrp_myshoping{
    margin-top: 30PX;
  }
  .header_myshoping{
    border-bottom: solid 1px #858585;
    padding: 20px 0;
    text-align: center;
    width: 100%;
    .tit_myshoping{
      display: inline-block;
      text-align: left;
      vertical-align: middle;
      h3, h4{
        font-family: 'dinblack';
        font-size: 12px;
        line-height: 15px;
      }
      h3{
        margin-bottom: 3px;
      }
      h4{
        font-family: 'dinregular';
      }
      & + .tit_myshoping{
        margin-left: 70px;
        @media screen and (max-width: 550px){
          margin-left: 0;
          margin-top: 20px;
        }
      }
      @media screen and (max-width: 550px){
        text-align: center;
        width: 100%;
      }
    }
  }
  .cont_myshop{
    @include clearfix();
    padding: 30px 40px;
    width: 100%;
    @media screen and (max-width: 900px){
      padding: 10px 20px 30px 10px;
    }
  }
  .lista_compras{
    text-align: center;
    width: 100%;
    li{
      float: left;
      list-style: none;
      text-align: left;
      width: 48%;
      &:nth-child(even){
        margin-left: 2%;
      }
      img{
        display: inline-block;
        vertical-align: middle;
        width: 100px;
      }
      .info_contmyshop{
        display: inline-block;
        @include calc(width, "100% - 130px");
        vertical-align: middle;
        h3{
          font-size: 13px;
          font-family: 'dinblack';
          line-height: 15px;
          margin-bottom: 10px;
        }
        p{
          font-size: 11px;
          font-family: 'dinlight';
          span{
            margin-left: 5px;
            font-size: 15px;
            font-family: 'dinmedium';
          }
        }
        button.button, a.button{
          font-size: 16px;
          padding: 12px 0;
          text-align: center;
          width: 100%;
          @media screen and (max-width: 550px){
            font-size: 12px;
          }
        }
        @media screen and (max-width: 1000px){
          padding: 0;
        }
      }
      @media screen and (max-width: 1000px){
        width: 100%;
      }
    }
  }
}

.wrrp_misdatos{
  margin: 100px 0 60px;
  color: $white;
  text-align: center;
  width: 100%;
p{
  font-family: 'dinlight';
  font-size: 14px;
  display: block;
  span{
    font-family: 'dinmedium';
  }
  & + P{
    margin-top: 30PX;
  }
}
}

// .easyzoom {
//     float: left;
// }
// .easyzoom img {
//     display: block;
// }
// .easyzoom {
//     display: inline-block;
// }
// .easyzoom img {
//     vertical-align: bottom;
// }