
.row_grills{
  margin-left: -20px;
  @include calc(width, '100% + 20px');
  .grill{
    float: left;
    margin-left: 20px;
    margin-bottom: 20px;
    &.grill50{
      @extend .grill;
      @include calc(width, '50% - 20px');
      @media screen and (max-width:750px) {
        @include calc(width, '100% - 20px');
      }
    }
    &.grill33{
      @extend .grill;
      @include calc(width, '33.3333333333% - 20px');
    }
    &.gril25{
      @extend .grill;
      @include calc(width, '25% - 20px');
      @media screen and (max-width:750px) {
        @include calc(width, '50% - 20px');
      }
    }
  }
  @media screen and (max-width:420px) {
    margin-left: 0;
    width: 100%;
    .grill{
      margin-left: 0;
      width: 100%;
    }
  }
}
