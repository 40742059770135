
/*Variables*/


//Colores

$white: #ffffff;
$black: #000;

// Fonts

//Generales

$max-index: 200;
$medium-index: 100;
$min-index: 1;
