
/*---------------------------------------------------
* Nombre del proyecto: Mil Demonios
* Nombre del archivo: main.css
* Descripción: Hoja de estilos global
* Fecha de creación: 10-12-2017
* Autor: Jose Montenegro

============COMENTARIOS============

* Ancho: full screen
* Numero de columnas: null
* Parallax: NO
* Responsivo: SI
---------------------------------------------------*/

@charset "UTF-8";
@import "_reset.scss";
@import "_fonts.scss";
@import "_mixins.scss";
@import "_variables.scss";
@import "_forms.scss";
@import "_grills.scss";
@import "_basic.scss";
