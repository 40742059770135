
@charset "UTF-8";

/*---RESET---*/

* {
  box-sizing: border-box;
  -moz-box-sizing: border-box;
  -webkit-box-sizing: border-box; }


a {
  text-decoration: none;
  color: inherit;
  display: block; }

a:hover, a:focus{
  text-decoration: none;}

.clear {
  clear: both; }

.clearfix {
  *zoom: 1; }

.clearfix:before,
.clearfix:after{
  display: table;
  line-height: 0;
  content: ""; }

.clearfix:after{
  clear: both; }

html, body, div, span, applet, object, iframe, h1, h2, h3, h4, h5, h6, p, blockquote, pre, a, abbr, acronym, address, big, cite, code, del, dfn, em, font, img, ins, kbd, q, s, samp, small, strike, strong, sub, sup, tt, var, dl, dt, dd, ol, ul, li, fieldset, form, label, legend, table, caption, tbody, tfoot, thead, tr, th, td{
  margin: 0;
  padding: 0;
  border: 0;
  outline: 0;
  font-weight: inherit;
  font-style: inherit;
  font-size: 100%;
  font-family: inherit;
  vertical-align: baseline; }


\:focus{
  outline: 0, }

body {
  line-height: 1; }

ol, ul {
  list-style: none; }

* {
  padding: 0;
  margin: 0;
  border: 0; }

a{
  display: block;
  text-decoration: none; }

img {
  padding: 0;
  margin: 0;
  border: 0; }
