//Mixins

@mixin clearfix(){
  *zoom: 1;
  &::before, &::after{
    content: "";
    display: table;
  }
  &::after{
    clear: both
  }
}

@mixin border-radius($radio){
  -webkit-border-radius: $radio;
  -moz-border-radius: $radio;
  -ms-border-radius: $radio;
  border-radius: $radio;
}

@mixin calc($initial, $operation){
  #{$initial}: -moz-calc(#{$operation});
  #{$initial}: -webkit-calc(#{$operation});
  #{$initial}: calc(#{$operation});
}

@mixin background-size($bg){
  background: $bg no-repeat center center fixed;
  -webkit-background-size: cover;
  -moz-background-size: cover;
  -o-background-size: cover;
  background-size: cover;
}

@mixin background-sizewithout(){
  background: no-repeat center center;
  -webkit-background-size: cover;
  -moz-background-size: cover;
  -o-background-size: cover;
  background-size: cover;
}

@mixin transform($transforms){
  -moz-transform: $transforms;
  -o-transform: $transforms;
  -ms-transform: $transforms;
  -webkit-transform: $transforms;
  transform: $transforms;
}

@mixin transition ($vel){
  transition: transform #{$vel}s
}

@mixin animado($ani){
  -webkit-transition: all $ani ease-out;
  -o-transition: all $ani ease-out;
  -moz-transition: all $ani ease-out;
  -ms-transition: all $ani ease-out;
  transition: all $ani ease-out;

}

@mixin translate ($x, $y){ 
  @include transform(translate($x, $y))
}

@mixin rotate($deg){
  @include transform(rotate(#{$deg}deg))
}

@mixin scale($w, $h){
  @include transform(scale($w, $h))
}

@mixin fontface($family, $path, $svg, $weight: normal, $style: normal){
  @font-face  {
    font-family: $family;
    src: url('#{$path}.eot');
    src: url('#{$path}.eot?#iefix') format('embedded-opentype'), 
    url('#{$path}.woff') format('woff'), 
    url('#{$path}.ttf') format('truetype'), 
    url('#{$path}.svg##{$svg}') format('svg');
    font-weight: $weight;
    font-style: $style;
  }
}

@mixin display-inline($align){
  display: inline-block;
  vertical-align: $align;
}

@mixin wrapper-table{
  display: table;
  height: 100%;
  width: 100%;
  & > div{
    display: table-cell;
    text-align: center;
    vertical-align: middle;
  }
}

@mixin opacity($value){
  $IEValue: $value*100;
  opacity: $value;
  -ms-filter: "progid:DXImageTransform.Microsoft.Alpha(Opacity="+$IEValue+")";
  filter: alpha(opacity=$IEValue);
}

@mixin boxshadow($shadow){
  -webkit-box-shadow: $shadow;
  -moz-box-shadow: $shadow;
  box-shadow: $shadow;
}

@mixin flchild ($pos){ // you can choose between the the first or the last 
  @if $pos == 'first' {
    &:first-child {
      @content;
    }
  }
  @if $pos == 'last' {
    &:last-child {
      @content;
    }
  }
}

@mixin nochild($num){ // you can chose the number of the child
  &:nth-child(#{$num}) {
    @content
  }
}



