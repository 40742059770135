// Fonts

@font-face {
    font-family: 'dinblack';
    src: url('../fonts/DINPro-Black.eot');
    src: url('../fonts/DINPro-Black.eot?#iefix') format('embedded-opentype'),
        url('../fonts/DINPro-Black.woff2') format('woff2'),
        url('../fonts/DINPro-Black.woff') format('woff'),
        url('../fonts/DINPro-Black.ttf') format('truetype'),
        url('../fonts/DINPro-Black.svg#DINPro-Black') format('svg');
    font-weight: 900;
    font-style: normal;
}

@font-face {
    font-family: 'dinbold';
    src: url('../fonts/DINPro-Bold.eot');
    src: url('../fonts/DINPro-Bold.eot?#iefix') format('embedded-opentype'),
        url('../fonts/DINPro-Bold.woff2') format('woff2'),
        url('../fonts/DINPro-Bold.woff') format('woff'),
        url('../fonts/DINPro-Bold.ttf') format('truetype'),
        url('../fonts/DINPro-Bold.svg#DINPro-Bold') format('svg');
    font-weight: bold;
    font-style: normal;
}

@font-face {
    font-family: 'dinmedium';
    src: url('../fonts/DINPro-Medium.eot');
    src: url('../fonts/DINPro-Medium.eot?#iefix') format('embedded-opentype'),
        url('../fonts/DINPro-Medium.woff2') format('woff2'),
        url('../fonts/DINPro-Medium.woff') format('woff'),
        url('../fonts/DINPro-Medium.ttf') format('truetype'),
        url('../fonts/DINPro-Medium.svg#DINPro-Medium') format('svg');
    font-weight: 500;
    font-style: normal;
}

@font-face {
    font-family: 'dinregular';
    src: url('../fonts/DINPro-Regular.eot');
    src: url('../fonts/DINPro-Regular.eot?#iefix') format('embedded-opentype'),
        url('../fonts/DINPro-Regular.woff2') format('woff2'),
        url('../fonts/DINPro-Regular.woff') format('woff'),
        url('../fonts/DINPro-Regular.ttf') format('truetype'),
        url('../fonts/DINPro-Regular.svg#DINPro-Regular') format('svg');
    font-weight: normal;
    font-style: normal;
}

@font-face {
    font-family: 'dinlight';
    src: url('../fonts/DINPro-Light.eot');
    src: url('../fonts/DINPro-Light.eot?#iefix') format('embedded-opentype'),
        url('../fonts/DINPro-Light.woff2') format('woff2'),
        url('../fonts/DINPro-Light.woff') format('woff'),
        url('../fonts/DINPro-Light.ttf') format('truetype'),
        url('DINPro-Light.svg#DINPro-Light') format('svg');
    font-weight: 300;
    font-style: normal;
}

@font-face {
    font-family: 'Pepperoninormal';
    src: url('../fonts/PepperoniSlab-Book.eot');
    src: url('../fonts/PepperoniSlab-Book.eot?#iefix') format('embedded-opentype'),
        url('../fonts/PepperoniSlab-Book.woff2') format('woff2'),
        url('../fonts/PepperoniSlab-Book.woff') format('woff'),
        url('../fonts/PepperoniSlab-Book.ttf') format('truetype'),
        url('../fonts/PepperoniSlab-Book.svg#PepperoniSlab-Book') format('svg');
    font-weight: normal;
    font-style: normal;
}

@font-face {
    font-family: 'Pepperonibold';
    src: url('../fonts/PepperoniSlab-Bold.eot');
    src: url('../fonts/PepperoniSlab-Bold.eot?#iefix') format('embedded-opentype'),
        url('../fonts/PepperoniSlab-Bold.woff2') format('woff2'),
        url('../fonts/PepperoniSlab-Bold.woff') format('woff'),
        url('../fonts/PepperoniSlab-Bold.ttf') format('truetype'),
        url('../fonts/PepperoniSlab-Bold.svg#PepperoniSlab-Bold') format('svg');
    font-weight: bold;
    font-style: normal;
}

@font-face {
    font-family: 'Brothersbold';
    src: url('../fonts/Brothers-Bold.eot');
    src: url('../fonts/Brothers-Bold.eot?#iefix') format('embedded-opentype'),
        url('../fonts/Brothers-Bold.woff2') format('woff2'),
        url('../fonts/Brothers-Bold.woff') format('woff'),
        url('../fonts/Brothers-Bold.ttf') format('truetype'),
        url('../fonts/Brothers-Bold.svg#Brothers-Bold') format('svg');
    font-weight: bold;
    font-style: normal;
}

@font-face {
    font-family: 'Pepperoniblack';
    src: url('../fonts/PepperoniSlab-Black.eot');
    src: url('../fonts/PepperoniSlab-Black.eot?#iefix') format('embedded-opentype'),
        url('../fonts/PepperoniSlab-Black.woff2') format('woff2'),
        url('../fonts/PepperoniSlab-Black.woff') format('woff'),
        url('../fonts/PepperoniSlab-Black.ttf') format('truetype'),
        url('../fonts/PepperoniSlab-Black.svg#PepperoniSlab-Black') format('svg');
    font-weight: 900;
    font-style: normal;
}













