/*Form*/

.cont_form{

  ::-webkit-input-placeholder { /* WebKit browsers */
    color:    $white;
    opacity: 1 !important;
  }

  input:-webkit-autofill {
    -webkit-box-shadow: 0 0 0 30px black inset;
    -webkit-text-fill-color: $white;
  }

  input[type=number]::-webkit-inner-spin-button, 
  input[type=number]::-webkit-outer-spin-button { 
      -webkit-appearance: none;
      -moz-appearance: none;
      appearance: none;
      margin: 0; 
  }

  input[type="text"],
  input[type="number"],
  input[type="tel"],
  input[type="email"],
  input[type="password"]{
    background: transparent;
    border: solid 1px $white;
    color: $white;
    font-family: 'dinmedium';
    line-height: 40px;  
    font-size: 14px;
    padding: 0 0 0 30px;
    margin-bottom: 3px;
    width: 100%;
  }
  select, .selected{
    background: transparent;
    border: solid 1px #ffffff;
    color: #ffffff;
    font-family: 'dinmedium';
    line-height: 40px;
    font-size: 14px;
    margin-bottom: 15px;
    padding: 0 0 0 30px;
    width: 100%;
    height: 40px;
  }
  .formgroup {
    margin-bottom: 15px;
    .validation-advice {
      font-size: 12px;
    }
  }

  input:-webkit-autofill {
    -webkit-box-shadow: 0 0 0 30px black  inset;
  }

  .wrrp_check{
    display: inline-block;
    height: 15px;
    overflow: hidden;
    position: relative;
    vertical-align: middle;
    width: 15px;
    label{
      border: solid 1px $white;
      cursor: pointer;
      left: 0;
      height: 100%;
      @include animado(0.2s);
      position: absolute;
      top: 0;
      width: 100%;
    }
    [type="checkbox"]:not(:checked),
    [type="checkbox"]:checked {
      position: absolute;
      left: -9999px;
    }
    [type="checkbox"]:checked + label{
      background: $white;
    }
  }

  .terminos{
    font-family: 'dinlight';
    font-size: 14px;
    display: inline-block;
    margin-left: 5px;
    vertical-align: middle;
    a{
      display: inline;
      font-family: 'dinblack';
      &:hover{
        text-decoration: underline;
      }
    }
  }

  .forgotyourpass{
    font-size: 14px;
    display: inline-block;
    margin-left: 20px;
    vertical-align: middle;
    font-family: 'dinblack';
    &:hover{
      text-decoration: underline;
    }
  }

  .alreadyhaceaccount{
    @extend .terminos;
    display: block;
    margin: 20px auto 0;
    text-align: center;
  }

  .btblur{
    margin-top: 25px;
    margin-bottom: 25px;
    position: relative;
    text-align: left;
    width: 100%;
  }
  
  .btblur p{
    font-family: 'dinmedium';
    color: #fff;
    line-height: 40px;
    padding: 0 0 0 30px;
    position: relative;
    z-index: 10;
  }
  
  .btblur span{
    filter: blur(2px);
    left: 0;
    background: #fff;
    height: 100%;
    opacity: 0.3;
    position: absolute;
    top: 0;
    width: 100%;
  }

  button.button{
    background: $white;
    color: black;
    &:hover{
      background: transparent;
      color: $white;
    }
  }

}



/*Form*/